"use client";

import React, { useMemo, useState } from "react";
import { useTranslations } from "next-intl";
// @ts-ignore: bigpicture TS is not available
import BigPicture from "bigpicture";
import CldImageList from "@/data/CldImageList";
import CldImage from "@/app-components/page/CldImage";
import {getCldImageUrl} from "next-cloudinary";

interface GalleryProps {
  isGrey: boolean;
  title: string;
}

type GalleryType = "all" | "interior" | "massages" | "cosmetics" | "exterior";
const types: GalleryType[] = ["all", "interior", "massages", "cosmetics", "exterior"];

function Gallery({ isGrey, title }: GalleryProps) {
  const t = useTranslations("page.gallery");

  const [selected, setSelected] = useState("all");

  const handleChange = (type: string) => {
    setSelected(type);
  };

  const selectetImages = useMemo(() => {
    switch (selected) {
      case "all":
        return [CldImageList.gallery.interior, CldImageList.gallery.massages, CldImageList.gallery.cosmetics, CldImageList.gallery.exterior].flat();
      case "interior":
        return CldImageList.gallery.interior;
      case "massages":
        return CldImageList.gallery.massages;
      case "cosmetics":
        return CldImageList.gallery.cosmetics;
      case "exterior":
        return CldImageList.gallery.exterior;
      default:
        return [];
    }
  }, [selected]);

  const handleClick = (e: any, position: number) => {
    BigPicture({
      el: e.target,
      //gallery: selectetImages.map((img) => ({ src: img })),
      gallery: selectetImages.map((imgPath) => ({
        src: getCldImageUrl({
          width: 1000,
          height: 1000,
          src: imgPath,
        })
      })),
      position,
      noLoader: true
    });
  };

  return (
    <section className={"py-15 py-xl-20" + (isGrey ? " bg-light" : "")}>
      <div className="container mt-5 mt-xl-10">
        <div className="row mb-10 g-3 g-xl-5 align-items-end">
          <div className="col-lg-8">
            <h1 className="display-1">{title}</h1>
          </div>
        </div>

        <div className="mb-5 ">
          {types.map((type: GalleryType) => {
            return (
              <button
                key={type}
                className={`btn btn-filter rounded-pill my-1 me-1 ${
                  selected === type ? "current" : ""
                }`}
                onClick={() => handleChange(type)}
              >
                {t(`type.${type}`)}
              </button>
            );
          })}
        </div>

        <div
          className="row mb-10 g-3 g-xl-5"
          data-isotope=""
          data-nosnippet
          id={"GALLERY_ID"}
        >
          {selectetImages.map((image, index) => (
            <div key={image} className={`col-md-6 col-lg-4`}>
              <div
                className="media media-image gallery-img-container"
                onClick={(e) => handleClick(e, index)}
              >

                <CldImage
                    width={600}
                    height={400}
                    alt={`${index}`}
                    src={image}
                    crop="fill"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Gallery;
