"use client";

import { CldImage as CldImageDefault, CldImageProps } from "next-cloudinary";

// https://next.cloudinary.dev/cldimage/configuration
const CldImage = (props: CldImageProps) => {
  return <CldImageDefault {...props} />;
};

export default CldImage;
