const CldImageList = {
  aboutMe: {
    Ulia: "/VoderStudio.com/ulia/Ulia-3748_dcdjht.png",
    Ulia2: "/VoderStudio.com/ulia/Yulia-Ruseva-Transparent_j1pyhn.png",
    UliaNearTable: "/VoderStudio.com/ulia/Yulia-Ruseva-3_oyoyow.jpg",
  },
  press: {
    Hero: "/VoderStudio.com/ulia/Yulia-Ruseva-5_xyrk9e.jpg"
  },
  gallery: {
    interior: [
      '/VoderStudio.com/studio/voder-studio-interrior-1_hmhbrn.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-2_sehp1b.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-3_hdggpw.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-12_z2naru.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-4_jti27e.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-5_agl3v3.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-6_bmptch.jpg',
      '/VoderStudio.com/studio/voder-studio-interrior-7_lducfm.jpg',
    ],
    exterior: [
      '/VoderStudio.com/studio/voder-studio-exterriror_swhzwi.jpg',
    ],
    massages:  [
      '/VoderStudio.com/massages/DSC03416_zypyct.jpg',
      '/VoderStudio.com/massages/DSC03449_kezarm.jpg',
      '/VoderStudio.com/massages/DSC03465_i4nrfx.jpg',
      '/VoderStudio.com/massages/DSC03469_pafkvr.jpg',
      '/VoderStudio.com/massages/DSC03476_i16q7h.jpg',
      '/VoderStudio.com/massages/DSC03494_mzu0sz.jpg',
      '/VoderStudio.com/massages/DSC03501_axjp8b.jpg',
      '/VoderStudio.com/massages/DSC03639_oiw3vp.jpg'
    ],
    cosmetics:  [
      '/VoderStudio.com/studio/voder-studio-interrior-11_pnnlvb.jpg',
    ],
  }
};

export default CldImageList;
