"use client";
import React from "react";
import Constants from "@/data/Constants";
import { trackGAEvent } from "@/lib/google-analytics";

function PhoneCta({
  children,
  classname,
  value,
}: {
  children: React.ReactNode;
  value: string;
  classname: string;
}) {
  const handlePhoneClick = () => {
    trackGAEvent("conversion", "phone_call", value);
  };
  return (
    <a
      key={value}
      onClick={handlePhoneClick}
      href={"tel:" + Constants.PHONE}
      className={classname}
    >
      {children}
    </a>
  );
}

export default PhoneCta;
